import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//initlaize multinlingual
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEN from './translations/en.json';
import translationsDE from './translations/de.json';
const userLang = navigator.language || navigator.userLanguage;



i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEN
    },
    de: {
      translation: translationsDE
    }
  },
  lng: userLang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
