import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const SizeSelector = ({ onSizeChange }) => {
    const [selectedSize, setSelectedSize] = useState('sm');
    const { t } = useTranslation();
    const handleChange = (event) => {
        setSelectedSize(event.target.value);
        onSizeChange(event.target.value);
    };


    return (
        <div className="flex items-center mt-2">
            <label htmlFor="Size-selector" className="font-bold mb-2">
                {t('sizeSelector.selectSize')}
            </label>
            <select
                id="Size-selector"
                value={selectedSize}
                onChange={handleChange}
                className="border rounded py-2 px-2 ml-2 mb-2"
            >
                <option value="sm">{t('sizeSelector.small')}</option>
                <option value="md">{t('sizeSelector.medium')}</option>
                <option value="lg">{t('sizeSelector.large')}</option>
            </select>
        </div>
    );
};
export default SizeSelector;
