import React, { useRef, useCallback } from 'react';

function audioBufferToWaveBlob(audioBuffer) {
    const numOfChan = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChan * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);

    let offset = 0;

    function writeString(view, offset, str) {
        for (let i = 0; i < str.length; i++) {
            view.setUint8(offset + i, str.charCodeAt(i));
        }
    }

    writeString(view, offset, 'RIFF'); offset += 4;
    view.setUint32(offset, 36 + length, true); offset += 4;
    writeString(view, offset, 'WAVE'); offset += 4;
    writeString(view, offset, 'fmt '); offset += 4;
    view.setUint32(offset, 16, true); offset += 4;
    view.setUint16(offset, 1, true); offset += 2;
    view.setUint16(offset, numOfChan, true); offset += 2;
    view.setUint32(offset, audioBuffer.sampleRate, true); offset += 4;
    view.setUint32(offset, audioBuffer.sampleRate * 2 * numOfChan, true); offset += 4;
    view.setUint16(offset, numOfChan * 2, true); offset += 2;
    view.setUint16(offset, 16, true); offset += 2;
    writeString(view, offset, 'data'); offset += 4;
    view.setUint32(offset, length, true); offset += 4;

    // Interleave audio channels
    const interleavedData = interleaveChannels(audioBuffer);

    for (let i = 0; i < interleavedData.length; i++) {
        view.setInt16(offset, interleavedData[i] * 0x7FFF, true);
        offset += 2;
    }

    return new Blob([buffer], { type: 'audio/wav' });
}

function interleaveChannels(audioBuffer) {
    const numChannels = audioBuffer.numberOfChannels;
    const numSamples = audioBuffer.length;
    const interleavedData = new Float32Array(numSamples * numChannels);

    for (let sample = 0; sample < numSamples; sample++) {
        for (let channel = 0; channel < numChannels; channel++) {
            interleavedData[sample * numChannels + channel] = audioBuffer.getChannelData(channel)[sample];
        }
    }

    return interleavedData;
}




const Test = () => {
    const fileInputRef = useRef(null);
    const downloadLinkRef = useRef(null);


    const processVideo = async (event) => {
        const videoFile = event.target.files[0];
        if (!videoFile) return;

        const arrayBuffer = await videoFile.arrayBuffer();
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        const audioBlob = audioBufferToWaveBlob(audioBuffer);
        const url = URL.createObjectURL(audioBlob);

        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = "audio.wav";
        downloadLinkRef.current.textContent = "Download Extracted Audio";
    };

    return (
        <div className="flex flex-col items-center">
            <input
                type="file"
                ref={fileInputRef}
                onChange={processVideo}
                accept="video/*"
                className="bg-white text-black py-2 px-4 mt-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <br />
            <a
                ref={downloadLinkRef}
                className="bg-blue-500 text-white py-2 px-4 mt-4 rounded-lg focus:outline-none hover:bg-blue-600"
            ></a>
            <div className="min-h-screen min-w-screen">


            </div>
        </div>
    );
};

export default Test;
