const audioBufferToWaveBlob = (audioBuffer) => {
    const numOfChan = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChan * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);

    let offset = 0;

    function writeString(view, offset, str) {
        for (let i = 0; i < str.length; i++) {
            view.setUint8(offset + i, str.charCodeAt(i));
        }
    }

    writeString(view, offset, 'RIFF'); offset += 4;
    view.setUint32(offset, 36 + length, true); offset += 4;
    writeString(view, offset, 'WAVE'); offset += 4;
    writeString(view, offset, 'fmt '); offset += 4;
    view.setUint32(offset, 16, true); offset += 4;
    view.setUint16(offset, 1, true); offset += 2;
    view.setUint16(offset, numOfChan, true); offset += 2;
    view.setUint32(offset, audioBuffer.sampleRate, true); offset += 4;
    view.setUint32(offset, audioBuffer.sampleRate * 2 * numOfChan, true); offset += 4;
    view.setUint16(offset, numOfChan * 2, true); offset += 2;
    view.setUint16(offset, 16, true); offset += 2;
    writeString(view, offset, 'data'); offset += 4;
    view.setUint32(offset, length, true); offset += 4;

    // Interleave audio channels
    const interleavedData = interleaveChannels(audioBuffer);

    for (let i = 0; i < interleavedData.length; i++) {
        view.setInt16(offset, interleavedData[i] * 0x7FFF, true);
        offset += 2;
    }

    return new Blob([buffer], { type: 'audio/wav' });
}

const interleaveChannels = (audioBuffer) => {
    const numChannels = audioBuffer.numberOfChannels;
    const numSamples = audioBuffer.length;
    const interleavedData = new Float32Array(numSamples * numChannels);

    for (let sample = 0; sample < numSamples; sample++) {
        for (let channel = 0; channel < numChannels; channel++) {
            interleavedData[sample * numChannels + channel] = audioBuffer.getChannelData(channel)[sample];
        }
    }

    return interleavedData;
}
export const processVideo = async (videoFile) => {
    if (!videoFile) return;

    const arrayBuffer = await videoFile.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const audioBlob = audioBufferToWaveBlob(audioBuffer);

    return audioBlob;
};
export const splitAudioData = (data, chunkSize) => {
    const chunks = [];
    const byteLength = atob(data.split(',')[1]).length; // Convert base64 to bytes
    const numChunks = Math.ceil(byteLength / chunkSize);

    for (let i = 0; i < numChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, byteLength);
        const byteData = atob(data.split(',')[1]).slice(start, end);
        const chunk = data.split(',')[0] + ',' + btoa(byteData);
        chunks.push(chunk);
    }
    return chunks;
};

