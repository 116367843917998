import { useState, useEffect } from 'react';
import { auth, db } from "../firebase";
import { collection, doc, getDoc } from 'firebase/firestore';


const Credits = () => {
    const currentUser = auth.currentUser

    const [credits, setCredits] = useState(null);

    useEffect(() => {
        const fetchCredits = async () => {

            const userDoc = doc(collection(db, 'users'), currentUser.uid);
            const docSnapshot = await getDoc(userDoc);
            if (docSnapshot.exists()) {
                setCredits(docSnapshot.data().credits);
                console.log(credits)
            } else {
                console.log('No such document!');
            }
        };

        fetchCredits();
    }, [currentUser]);


    let creditMessage;

    if (credits === null) {
        creditMessage = (
            <p className="text-gray-500 text-sm">Loading credits...</p>
        );
    } else if (credits === 0) {
        creditMessage = "You have no credits left."
    } else if (credits === 1) {
        creditMessage = "You have 1 credit remaining."
    } else {
        creditMessage = `You have ${credits} credits remaining.`
    }
    return (
        <div className='text-center' >
            {credits === null ? (
                <p className="text-lg">Loading...</p>
            ) : (
                <p className="text-lg">{creditMessage}</p>
            )
            }
        </div >
    );
};

export default Credits;
