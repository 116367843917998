import React from 'react';
import { auth } from "../firebase";
import PlanUsage from './PlanUsage';
import PayPalCheckout from './PayPalCheckout';
import GoogleButton from './GoogleButton';
import { useTranslation } from 'react-i18next';

function Pricing() {
    const { t } = useTranslation();

    return (
        <div>


            <div className="flex flex-col md:flex-row md:justify-center">


                <div className="bg-white shadow-lg rounded-lg w-80 m-6">
                    <div className="px-10 py-6">
                        <div className="text-4xl font-bold text-center mb-4">9.99€ </div>
                        <div className='flex justify-center'>
                            <img className="transform scale-x-[-1] h-32 w-32 object-cover mr-4" src="./images/friendlyRobo2.png" alt="friendly robot" />
                        </div>


                        <div className="text-xl font-semibold text-center mb-4">1.000 Credits</div>
                        <hr className="mb-6" />
                        <div className="text-gray-600 mb-4">
                            <div className="flex items-center">
                                -
                                Up to 10 hours of audio summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                Up to 100 Text summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                Up to 100 Flashcards
                            </div>
                        </div>
                        {auth.currentUser ? (
                            <PayPalCheckout amount={9.99} />
                        ) : (
                            <div className="flex flex-col items-center">
                                <p className="text-gray-700 mt-2">
                                    You have to be logged in to buy something
                                </p>
                            </div>

                        )}
                    </div>
                </div>
                <div className="bg-white shadow-lg rounded-lg w-80 m-6">
                    <div className="px-10 py-6">
                        <div className="text-4xl font-bold text-center mb-4">19.99€</div>
                        <div className='flex justify-center'>
                            <img className="h-32 w-32 object-cover mr-4" src="./images/friendlyRobo3.png" alt="friendly robot" />
                        </div>

                        <div className="text-xl font-semibold text-center mb-4">2.500 Credits</div>
                        <hr className="mb-6" />
                        <div className="text-gray-600 mb-4">
                            <div className="flex items-center">
                                -
                                up to 25 hours of audio summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                up to 250 Text summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                up to 250 Flashcards
                            </div>
                        </div>
                        {auth.currentUser ? (
                            <PayPalCheckout amount={19.99} />
                        ) : (
                            <div className="flex flex-col items-center">
                                <p className="text-gray-700 mt-2">
                                    You have to be logged in to buy something
                                </p>
                            </div>

                        )}
                    </div>
                </div>
                <div className="bg-white shadow-lg rounded-lg w-80 m-6">
                    <div className="px-10 py-6">
                        <div className="text-4xl font-bold text-center mb-4">49.99€</div>
                        <div className='flex justify-center'>
                            <img className="h-32 w-32 object-cover mr-4" src="./images/friendlyRobo6.png" alt="friendly robot" />
                        </div>

                        <div className="text-xl font-semibold text-center mb-4">7.500 Credits</div>
                        <hr className="mb-6" />
                        <div className="text-gray-600 mb-4">
                            <div className="flex items-center">
                                -
                                Up to 75 hours of audio summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                Up to 750 Text summarizations
                            </div>
                            <div className="flex items-center">
                                -
                                Up to 750 Flashcards
                            </div>

                        </div>
                        {auth.currentUser ? (
                            <PayPalCheckout amount={49.99} />
                        ) : (
                            <div className="flex flex-col items-center">
                                <p className="text-gray-700 mt-2">
                                    You have to be logged in to buy something
                                </p>
                            </div>

                        )}
                    </div>
                </div>
            </div>
            {auth.currentUser ? (
                <div>
                    <PlanUsage />
                </div>
            ) : (
                <div className="flex flex-col items-center">

                    <p className="text-gray-700 mt-2 mb-4">
                        You have to be logged in to see your credits
                    </p>
                    <GoogleButton />

                </div>

            )}

        </div>
    );
}

export default Pricing;

