import React from 'react';

function Impressum() {
    return (
        <div className="container mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6">Impressum</h1>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <p className="font-bold">Name:</p>
                    <p>Study Swift</p>
                    <p className="font-bold">Rechtsform:</p>
                    <p>Einzelunternehmen</p>
                    <p className="font-bold">Vertretungsberechtigte Person:</p>
                    <p>Paul Permantier</p>
                    <p className="font-bold">Ladungsfähige Anschrift:</p>
                    <p>Elisabeth-Feller-Weg 21</p>
                    <p>12205 Berlin</p>
                    <p>Deutschland</p>
                </div>
                <div>
                    <p className="font-bold">Kontaktdaten:</p>
                    <p>Telefon: 01759139160</p>
                    <p>E-Mail: info@study-swift.com</p>

                    {/* <p className="font-bold">Register:</p>
                    <p>Amtsgericht Musterstadt</p>
                    <p>Registernummer: HRB 12345 (nur bei Eintragung ins Handelsregister)</p> */}
                    {/* <p className="font-bold">Umsatzsteuer-Identifikations-Nummer:</p>
                    <p>DE 123456789 (nur sofern vorhanden)</p> */}
                    {/* <p className="font-bold">Berufsaufsichtsbehörde:</p>
                    <p>Industrie- und Handelskammer Musterstadt</p>
                    <p>Musterstraße 2</p>
                    <p>12345 Musterstadt</p>
                    <p className="font-bold">Berufspezifische Informationen:</p>
                    <p>(nicht erforderlich)</p> */}
                </div>
            </div>
        </div>
    );
}


export default Impressum;
