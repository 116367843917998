import { FiLogOut } from "react-icons/fi";
import React from "react"

function SignOutButton({ handleSignOut }) {
    return (
        <div className="flex items-center ">
            <button
                onClick={handleSignOut}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
                <FiLogOut className="mr-2 h-5 w-5" />
                Sign Out
            </button>
        </div>
    );
}

export default SignOutButton;