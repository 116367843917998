import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import GoogleButton from './GoogleButton';
import { useTranslation } from 'react-i18next';

function Explanation() {
    const { t } = useTranslation();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <section className="bg-white text-gray-700 body-font">
            <div className="container px-5 pt-20 mx-auto flex flex-wrap">
                <div className="lg:w-1/3 w-full rounded-lg overflow-hidden">
                    <img
                        alt="feature"
                        className="transform scale-x-[-1] object-cover object-center h-full w-full"
                        src="/images/friendlyRobo.png"
                    />
                </div>
                <div className="lg:w-2/3 w-full lg:pl-10 lg:py-6">
                    <h1 className="text-3xl font-medium mb-4 text-gray-900">
                        {t('explanation.howItWorks')}
                    </h1>
                    <div className="leading-relaxed mb-4">
                        <p className="mb-4 text-xl font-medium">
                            {t('explanation.dropFileAndWait')}
                        </p>
                        <p className="text-lg">
                            {t('explanation.aiTechnology')}
                        </p>
                        {!user && (
                            <div className="mt-4">
                                <p className="text-lg mb-4">{t('explanation.loginToStart')}</p>
                                <GoogleButton />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Explanation;
