import React from 'react';
import { useTranslation } from 'react-i18next';
import ValuesSection from './ValueSection';

function AboutPage() {
    const { t } = useTranslation();

    return (
        <div className="w-full ">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">{t('aboutPage.hi')}</h2>
                    <p className="mt-4 text-lg text-gray-500">
                        {t('aboutPage.welcome')}
                    </p>
                    <ValuesSection />
                    <div className="mt-10">
                        <h3 className="text-lg font-bold text-gray-900">{t('aboutPage.aboutMe')}</h3>
                        <div className="mt-4">
                            <div className='flex justify-center items-center'>
                                <img className="h-64 w-64 rounded-full object-cover object-center" style={{ objectPosition: 'center top' }} src="./images/CEO.jpg" alt={t('aboutPage.paulHeadshotAlt')} />

                            </div>

                            <div className='mt-5'>
                                <h4 className="text-lg font-bold text-gray-900">{t('aboutPage.paulPermantier')}</h4>
                                <p className="text-gray-500">{t('aboutPage.founderMessage')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
