import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


function TextFileDownload({ initialText }) {
    const [text, setText] = useState(initialText);
    const { t } = useTranslation();
    useEffect(() => {
        setText(initialText);
    }, [initialText]);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([text], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'mytextfile.txt';
        document.body.appendChild(element);
        element.click();
    };

    return (
        <div className="bg-white  rounded px-8 pt-6 pb-8 mb-4 w-full">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="text">
                    {t("textFileDownload.label")}
                </label>
                <textarea
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="text"
                    rows="5"
                    placeholder="Enter your text here"
                    value={text}
                    onChange={handleTextChange}
                />
            </div>
            <div className="flex items-center justify-end">
                <button
                    className=" bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleDownload}
                >
                    {t("textFileDownload.button")}
                </button>
            </div>
        </div>
    );
}

export default TextFileDownload;