import { useEffect } from "react";
import { auth } from "../firebase";
import { addCredits } from '../firebase';

import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

// This values are the props in the UI

const currency = "EUR";

const style = { "layout": "vertical", "color": "silver" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner, amount }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);


    return (<>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
            style={style}
            disabled={false}
            forceReRender={[amount, currency, style]}
            fundingSource={undefined}
            createOrder={(data, actions) => {
                return actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: currency,
                                    value: amount,
                                },
                            },
                        ],
                    })
                    .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                    });
            }}
            onApprove={function (data, actions) {
                return actions.order.capture().then(function () {
                    if (amount === 9.99) {
                        addCredits(auth.currentUser.uid, 1000)
                    } else if (amount === 19.99) {
                        addCredits(auth.currentUser.uid, 1000)
                    } else if (amount === 49.99) {
                        addCredits(auth.currentUser.uid, 1000)
                    }
                });
            }}
        />
    </>
    );
}

export default function PayPalCheckout({ amount }) {
    return (
        <div style={{ maxWidth: "750px", minHeight: "200px" }}>

            <ButtonWrapper
                amount={amount}

                currency={currency}
                showSpinner={false}
            />

        </div >
    );
}