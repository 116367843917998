import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Header() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);

    const handleMenuToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="w-full flex items-center justify-between flex-wrap bg-gray-800 py-3 px-6 shadow-md">
            <div className="flex items-center flex-shrink-0 mr-6">
                <Link to="/" className="text-2xl font-semibold text-white">
                    Study Swift
                </Link>
            </div>
            <div className="block lg:hidden">
                <button
                    onClick={handleMenuToggle}
                    className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-gray-200 hover:border-gray-200 focus:outline-none"
                >
                    {isOpen ? (
                        <AiOutlineClose className="w-5 h-5" />
                    ) : (
                        <FaBars className="w-5 h-5" />
                    )}
                </button>
            </div>
            <div
                className={`${isOpen ? 'block' : 'hidden'
                    } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
            >
                <div className="text-sm lg:flex-grow lg:text-right">
                    <Link
                        to="/"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-4 hover:no-underline"
                    >
                        {t('header.product')}
                    </Link>
                    <Link
                        to="/about"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-4 hover:no-underline"
                    >
                        {t('header.about')}
                    </Link>
                    <Link
                        to="/pricing"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-4 hover:no-underline"
                    >
                        {t('header.pricing')}
                    </Link>
                    <Link
                        to="/login"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 hover:no-underline"
                    >
                        {t('header.login')}
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Header;
