import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) =>
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => {
        let alertClasses;

        switch (alert.alertType) {
            case "danger":
                alertClasses = "opacity-80 fixed  text-center text-lg uppercase py-5  w-full bg-red-500 text-white";
                break;
            case "success":
                alertClasses = "opacity-80 fixed  text-center text-lg uppercase py-5  w-full bg-green-500 text-white";
                break;
            case "information":
                alertClasses = "opacity-80 fixed text-center text-lg uppercase py-5  w-full bg-blue-500 text-white";
                break;
            default:
                break;
        }
        return (
            <div key={alert.id} className={alertClasses}>
                {alert.msg}
            </div>
        );
    });

Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
