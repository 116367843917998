import React from 'react';

const DownloadTranscriptButton = ({ transcript }) => {
    const downloadTranscript = () => {
        const element = document.createElement('a');
        const file = new Blob([transcript], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'transcript.txt';
        document.body.appendChild(element);
        element.click();
    };

    return (
        <button
            className="bg-gray-800 hover:bg-gray-900 text-white py-2 px-4 rounded"
            onClick={downloadTranscript}
        >
            Download Transcript
        </button>
    );
};

export default DownloadTranscriptButton;
