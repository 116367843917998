import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// import Auth from './components/Auth';
import AboutPage from './components/AboutPage.js';
import Home from './components/Home';
import Pricing from './components/Pricing';
import './App.css';

import LoginPage from './components/LoginPage';
import Impressum from './components/Impressum';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Test from './components/Test';
import Alert from './components/microComponents/Alert';
import Transcripts from './components/Transcripts';
//redux
import { Provider } from "react-redux";
import store from "./redux/store";



function App() {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_ID_REAL,
        components: "buttons",
        currency: "USD",
        'disable-funding': 'giropay,sepa',
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">

            <Header />
            <Alert />
            <div className='body'>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/test" element={< Test />} />
                <Route path="/transcripts" element={< Transcripts />} />


                <Route path="/login" element={<LoginPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/impressum" element={<Impressum />} />

                <Route path="/contact" element={<Contact />} />
              </Routes>

            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </Provider>

    </PayPalScriptProvider>
  );
}

export default App;

