import React from "react";

import { provider, googleSignIn } from "../firebase.js";
import { FaGoogle } from "react-icons/fa";




const GoogleButton = () => {
    return (
        <button
            className="bg-white text-gray-800 font-bold py-2 px-4 border border-gray-400 rounded shadow flex items-center"
            onClick={googleSignIn}
        >
            <FaGoogle className="mr-2" />
            <span>Sign in with Google</span>
        </button>
    );
};



export default GoogleButton;
