import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const LanguageSelector = ({ onLanguageChange }) => {
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const { t } = useTranslation();

    const handleChange = (event) => {
        setSelectedLanguage(event.target.value);
        onLanguageChange(event.target.value);
    };


    return (
        <div className="flex items-center mt-2">
            <label htmlFor="language-selector" className="font-bold mb-2">
                {t('languageSelector.selectLanguage')}
            </label>
            <select
                id="language-selector"
                value={selectedLanguage}
                onChange={handleChange}
                className="border rounded py-2 px-2 ml-2 mb-2"
            >
                <option value="en">{t('languageSelector.english')}</option>
                <option value="es">{t('languageSelector.spanish')}</option>
                <option value="fr">{t('languageSelector.french')}</option>
                <option value="de">{t('languageSelector.german')}</option>
                <option value="ru">{t('languageSelector.russian')}</option>
            </select>
        </div>
    );
};
export default LanguageSelector;
