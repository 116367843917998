import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { deductCredits } from '../firebase';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import TextFileDownload from './TextFileDownload';
import Spinner from './microComponents/Spinner';
import LanguageSelector from './microComponents/LanguageSelector';
import DownloadTranscriptButton from './microComponents/DownloadTranscriptButton';
import { processVideo, splitAudioData } from './proccesingLogic/fileConversion';


import SizeSelector from './microComponents/SizeSelector';

import { setAlert } from "../redux/alert.action.js";
import { connect } from "react-redux";
import PropTypes from "prop-types";


function FileDrop({ setAlert }) {

    useEffect(() => {
        if (PDFJS) {
            PDFJS.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;
        }

    }, []);
    const isIpad = () => {
        return /iPad|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    };

    let PDFJS;
    if (!isIpad()) {
        PDFJS = require('pdfjs-dist');
    }

    const [file, setFile] = useState(null);
    const [type, setType] = useState('')
    const [confirmed, setConfirmed] = useState(false)
    const [estimatedTime, setEstimatedTime] = useState(0)
    const [fileText, setFileText] = useState('')
    const [credits, setCredits] = useState(null);
    const [summary, setSummary] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [transcript, setTranscript] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [selectedSize, setSelectedSize] = useState('sm');

    const { t } = useTranslation();
    const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
    };
    const handleSizeChange = (newSize) => {
        setSelectedSize(newSize);
    };



    const ectractTextfromFile = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                const text = reader.result

                setFileText(text)
                resolve(text);
            };
        });
    }

    const estimateProcessingTime = (fileSize, wordCount) => {
        const fileSizeInMB = fileSize / (1024 * 1024);
        const audioProcessingTime = (fileSizeInMB / 6.5) * 30;
        const textProcessingTime = (wordCount / 2000) * 10;

        if (fileSize) {
            setEstimatedTime(audioProcessingTime)
            return;
        } else {
            setEstimatedTime(textProcessingTime)
            return;
        }
    };
    const estimateDropTime = (fileSize, wordCount) => {
        const fileSizeInMB = fileSize / (1024 * 1024);
        const audioProcessingTime = (fileSizeInMB / 6.5) * 3;
        const textProcessingTime = 1.5

        if (fileSize) {
            setEstimatedTime(audioProcessingTime)
            return;
        } else {
            setEstimatedTime(textProcessingTime)
            return;
        }
    };

    //handle pdf text
    const getPageText = async (pdf, pageNo) => {
        const page = await pdf.getPage(pageNo);
        const tokenizedText = await page.getTextContent();
        const pageText = tokenizedText.items.map(token => token.str).join('');
        return pageText;
    };
    const getPDFText = async (source) => {
        const pdf = await PDFJS.getDocument(source).promise;
        const maxPages = pdf.numPages;
        const pageTextPromises = [];
        for (let pageNo = 1; pageNo <= maxPages; pageNo += 1) {
            pageTextPromises.push(getPageText(pdf, pageNo));
        }
        const pageTexts = await Promise.all(pageTextPromises);
        const fullText = pageTexts.join(' ');
        setFileText(fullText)

        return fullText;
    };



    const calculateCredits = async (file) => {
        const isAudioFile = file.type.includes('audio');
        const isPDF = file.type.includes('pdf');
        const isVideoFile = file.type.includes('video');
        if (isAudioFile) {
            const audioBlob = new Blob([file], { type: file.type });
            const audioContext = new AudioContext();
            try {
                const audioBuffer = await audioContext.decodeAudioData(await audioBlob.arrayBuffer());
                const audioLengthInSeconds = audioBuffer.duration;
                const creditsPerMinute = 1.6;
                const creditsRequired = Math.ceil(audioLengthInSeconds / 60 * creditsPerMinute);
                return creditsRequired;
            } catch (error) {
                console.log(error);
                setAlert('An error occurred while processing your audio file. Please try again.', 'danger');
                throw new Error('Error decoding audio data');
            }
        } else if (isPDF) {
            const creditsRequired = await file.arrayBuffer().then(async (ab) => {
                const textStringAlt = await getPDFText(ab);
                const textString = JSON.stringify(textStringAlt)

                const textLengthInWords = textString.split(' ').length;
                //make this more percise later
                const creditsPerWord = 0.003;
                const creditsRequired = Math.ceil(textLengthInWords * creditsPerWord);

                return creditsRequired
            });
            return creditsRequired
        } else if (isVideoFile) {
            const videoBlob = new Blob([file], { type: file.type });
            const url = URL.createObjectURL(videoBlob);
            const video = document.createElement('video');

            const duration = await new Promise((resolve, reject) => {
                video.onloadedmetadata = () => {
                    resolve(video.duration);
                };
                video.onerror = () => {
                    reject(new Error('Error loading video file'));
                };
                video.src = url;
            });

            URL.revokeObjectURL(url);
            const creditsPerMinute = 1.6;
            const creditsRequired = Math.ceil(duration / 60 * creditsPerMinute);
            return creditsRequired;
        } else {
            const text = await ectractTextfromFile(file);
            const textString = JSON.stringify(text);
            const textLengthInWords = textString.split(' ').length;
            //make this more precise later
            const creditsPerWord = 0.003;
            const creditsRequired = Math.ceil(textLengthInWords * creditsPerWord);
            return creditsRequired;

        }
    };


    const handleDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        console.log(file.type)
        //estimate drop time
        if (file.type.includes("audio")) {
            estimateDropTime(file.size, 0);
        } else {
            const textString = JSON.stringify(fileText);
            const textLengthInWords = textString.split(" ").length;
            estimateDropTime(0, textLengthInWords);
        }
        //check if file type is valid
        if (file.type === "audio/mpeg" || file.type === "audio/x-m4a" || file.type === "audio/wav") {
            setType("audio/mpeg")
        } else if (file.type === "text/plain") {
            setType("text/plain")
        } else if (file.type === "application/pdf") {
            setType("application/pdf")
        } else if (file.type === "video/mp4") {
            setType("video/mp4")
        } else {
            setErrorMessage('Invalid file type. Please select a file with a valid Type');
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
            return;
        }
        //handle Ipad pdf error
        if (isIpad() && file.type === "application/pdf") {
            setErrorMessage('PDFs dont work on Ipads yet sorry. Please use Desktop or Mobile.');
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
            return
        }
        //check if logged in
        if (!auth.currentUser) {
            setErrorMessage('Please Login to use this service');
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
            return;
        }


        setIsLoading(true);
        const isVideoFile = file.type.includes('video');
        if (isVideoFile) {
            const audioBlob = await processVideo(file);
            // Now we create a new File object from the blob.
            // We will use this File object in the rest of your existing code.
            const audioFile = new File([audioBlob], "audio.wav", {
                type: 'audio/wav',
            });


            setFile(audioFile); // Replace the video file with the new audio file.
        }


        //calculate credits
        const newCredits = await calculateCredits(file);

        setCredits(newCredits);
        if (!isVideoFile) {

            setFile(file);
        }
        setIsLoading(false);


    };



    const handleConfirm = async () => {


        if (file.type.includes("audio")) {
            estimateProcessingTime(file.size, 0);
        } else {
            const textString = JSON.stringify(fileText);
            const textLengthInWords = textString.split(" ").length;
            estimateProcessingTime(0, textLengthInWords);
        }
        const isAudioFile = file.type.includes('audio');

        if (isAudioFile) {

            //read out file
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const audioData = reader.result;
                const chunkSize = 6 * 1024 * 1024; // 6MB in bytes
                const audioChunks = splitAudioData(audioData, chunkSize);
                // Process audio chunks
                const transcripts = [];


                for (const audioChunk of audioChunks) {
                    try {


                        setIsLoading(true);

                        const isProduction = process.env.NODE_ENV === 'production';
                        const apiUrl = isProduction
                            ? "https://us-central1-epic-summariser-ba8eb.cloudfunctions.net/transcribe_and_summarize"
                            : "http://127.0.0.1:5001/epic-summariser-ba8eb/us-central1/transcribe_and_summarize";

                        const response = await axios.post(
                            apiUrl,
                            {
                                audio: "free",
                                language: selectedLanguage,
                                size: selectedSize,
                                test: audioChunk, // Send the base64-encoded audio data
                                mimetype: file.type // Send the mimetype of the file
                            },
                            {
                                headers: {
                                    // Add the following line to set the Content-Length header
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "POST",
                                    "Access-Control-Allow-Headers": "Content-Type",
                                },
                            }
                        );

                        transcripts.push(response.data.transcript);




                    } catch (error) {
                        setIsLoading(false);
                        setAlert('There was an error Internal Server Error please try again later.', 'danger');
                        console.log(error.response);
                    }
                }

                // Combine the transcripts and summaries
                const combinedTranscript = transcripts.join(' ');
                try {
                    const idToken = await auth.currentUser.getIdToken();

                    const isProduction = process.env.NODE_ENV === 'production';
                    const apiUrl = isProduction
                        ? "https://us-central1-epic-summariser-ba8eb.cloudfunctions.net/summarize"
                        : "http://127.0.0.1:5001/epic-summariser-ba8eb/us-central1/summarize";

                    const response = await axios.post(apiUrl, { text: combinedTranscript, size: selectedSize, language: selectedLanguage }, {
                        headers: {
                            'Authorization': `Bearer ${idToken}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'POST',
                            'Access-Control-Allow-Headers': 'Content-Type',
                        },
                    });

                    setSummary(response);
                } catch (error) {
                    setAlert('There was an error Internal Server Error please try again later.', 'danger');
                    setIsLoading(false);
                    console.log(error.response);
                }

                setTranscript(combinedTranscript);
                setConfirmed(true)
                deductCredits(auth.currentUser.uid, credits)
                setEstimatedTime(0)
                setIsLoading(false);
            };

        } else {
            try {
                const idToken = await auth.currentUser.getIdToken();
                setIsLoading(true);

                const isProduction = process.env.NODE_ENV === 'production';
                const apiUrl = isProduction
                    ? "https://us-central1-epic-summariser-ba8eb.cloudfunctions.net/summarize"
                    : "http://127.0.0.1:5001/epic-summariser-ba8eb/us-central1/summarize";

                const response = await axios.post(apiUrl, { text: fileText, size: selectedSize, language: selectedLanguage }, {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'POST',
                        'Access-Control-Allow-Headers': 'Content-Type',
                    },
                });

                setSummary(response);
                setConfirmed(true)
                deductCredits(auth.currentUser.uid, credits)
                setIsLoading(false);

            } catch (error) {
                console.log(error.response);
                setAlert('An error occurred while processing your audio file. Please try again.', 'danger');
                setIsLoading(false);

            }
        }
    };
    return (
        <div className="flex flex-col relative items-center justify-center w-full">
            {isLoading && (
                <div>

                    <div className="fixed left-0 inset-0 w-screen flex flex-col justify-center z-10 opacity-90 bg-gray-800 items-center"

                    >
                        <Spinner />
                        {(estimateProcessingTime !== 0) && (
                            <p className="mt-4 text-center text-white">{`${t("fileDrop.estimatedTime")} ${estimatedTime.toFixed(1)} ${t("fileDrop.seconds")}.`}</p>
                        )}
                    </div>
                </div>

            )}
            <div className="relative flex flex-col md:flex-row">
                <div className='mt-10 px-5'>
                    <p className='text-lg font-semibold'>{t('fileDrop.acceptedFileTypes')}</p>
                    <div className='flex '>
                        <div className='flex flex-col items-center mt-1 '>
                            <img src='./images/readingRobo.png' alt='txt-icon' className=' w-20 mb-2' />
                            <p>{t('fileDrop.txt')}</p>
                        </div>
                        <div className='flex flex-col items-center mt-1 ml-5'>
                            <img src='./images/audioRobo.png' alt='audio-icon' className=' w-20 mb-2' />
                            <p>{t('fileDrop.audio')}</p>
                        </div>
                        <div className='flex flex-col items-center mt-1 ml-5'>
                            <img src='./images/audioRobo.png' alt='video-icon' className=' w-20 mb-2' />
                            <p>{t('fileDrop.video')}</p>
                        </div>
                    </div>

                </div>
                <div className='mt-8 md:mt-0 md:ml-10 mx-auto'>
                    <Dropzone onDrop={handleDrop} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="mt-4">
                                <input {...getInputProps()} />
                                {errorMessage && (
                                    <div className="absolute bottom-4 bg-red-500 text-white px-4 py-2 rounded-md">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className="w-64 h-64 border-4 border-dashed border-gray-400 flex justify-center items-center rounded-lg">
                                    {file ? (
                                        <p className="text-gray-900 text-center text-sm font-medium uppercase">{t('fileDrop.selectedFile')} {file.name}</p>

                                    ) : (
                                        <p className="text-gray-900 text-lg font-medium uppercase">{t('fileDrop.dropInHere')}</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <LanguageSelector onLanguageChange={handleLanguageChange} />
                    <SizeSelector onSizeChange={handleSizeChange} />
                    {transcript && <DownloadTranscriptButton transcript={transcript} />}
                </div>
            </div>

            {
                file && (
                    <div className='mt-6'>
                        <div className='flex justify-center'>
                            <button
                                className=" bg-gray-800 mb-5 hover:bg-gray-900 text-white py-2 px-4 rounded-lg mt-4"
                                onClick={handleConfirm}
                            >
                                {t('fileDrop.submit')}
                            </button>
                        </div>

                        <p className="text-md font-medium">{t('fileDrop.creditsToCompute')} {credits}</p>
                    </div>
                )
            }

            {confirmed ? (<TextFileDownload initialText={summary.data.summaryText} />)
                : (<TextFileDownload initialText={t('fileDrop.summaryAppearSoon')} />)
            }
        </div>
    );
}

FileDrop.propTypes = {
    setAlert: PropTypes.func.isRequired,

}


export default connect(null, { setAlert })(FileDrop)

