

import "firebase/auth";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, updateDoc, getDoc, doc, setDoc } from "firebase/firestore";
// import { getFunctions, httpsCallable } from "firebase/functions";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "epic-summariser-ba8eb.firebaseapp.com",
    projectId: "epic-summariser-ba8eb",
    storageBucket: "epic-summariser-ba8eb.appspot.com",
    messagingSenderId: "536914777062",
    appId: "1:536914777062:web:34c2df4600e314701e3c27",
    measurementId: "G-KJVL3P8XR4"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
// analytics if needed
// const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

//initialize functions
// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

export const auth = getAuth(app)


export const provider = new GoogleAuthProvider();
// export const auth = app.auth()
export const googleSignIn = async () => {
    try {
        const result = await signInWithPopup(auth, provider);

        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...


        const uid = user.uid;

        try {
            const userRef = doc(db, "users", uid)
            const userDoc = await getDoc(userRef)
            if (userDoc.exists()) {
                console.log("User already exists in Firestore");
            } else {
                const userData = {
                    subscriptionStatus: "free",
                    credits: 200,
                    email: user.email
                };
                await setDoc(doc(db, "users", uid),
                    userData
                );
                console.log("New user document added to Firestore");
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }


    } catch (error) {
        console.log(error);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
    }
};

export const deductCredits = async (userId, creditsToDeduct) => {
    const userRef = doc(db, "users", userId);

    try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            const userCredits = userDoc.data().credits;
            if (userCredits >= creditsToDeduct) {
                const remainingCredits = userCredits - creditsToDeduct;
                const updates = {
                    credits: remainingCredits
                };
                await updateDoc(userRef, updates);
                console.log(`Successfully deducted ${creditsToDeduct} credits from user ${userId}`);
            } else {
                console.log(`User ${userId} does not have enough credits`);
            }
        } else {
            console.log(`User ${userId} does not exist in Firestore`);
        }
    } catch (e) {
        console.error(`Error deducting credits from user ${userId}: `, e);
    }
};

export const addCredits = async (userId, creditsToAdd) => {
    const userRef = doc(db, "users", userId);

    try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            const userCredits = userDoc.data().credits;

            const newCredits = userCredits + creditsToAdd;
            const updates = {
                credits: newCredits
            };
            await updateDoc(userRef, updates);
            console.log(`Successfully added ${creditsToAdd} credits from user ${userId}`);

        } else {
            console.log(`User ${userId} does not exist in Firestore`);
        }
    } catch (e) {
        console.error(`Error deducting credits from user ${userId}: `, e);
    }
};

export const signInWithEmail = async (email, password) => {
    try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const user = result.user;
        console.log("User signed in with email:", user);
    } catch (error) {
        console.error("Error signing in with email:", error);
    }
};

export const signUpWithEmail = async (email, password) => {
    try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const user = result.user;
        console.log("User signed up with email:", user);

        // Add the new user document to Firestore
        const uid = user.uid;

        try {
            const userRef = doc(db, "users", uid);
            const userDoc = await getDoc(userRef);
            if (!userDoc.exists()) {
                const userData = {
                    subscriptionStatus: "free",
                    credits: 200,
                    email: user.email,
                };
                await setDoc(doc(db, "users", uid), userData);
                console.log("New user document added to Firestore");
            } else {
                console.log("User already exists in Firestore");
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    } catch (error) {
        console.error("Error signing up with email:", error);
    }
};
