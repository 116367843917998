import { useEffect, useState } from 'react';
import { auth } from '../firebase';
import GoogleButton from './GoogleButton';
import SignOutButton from './microComponents/SignOutButton';
import { useTranslation } from 'react-i18next';
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import { setAlert } from "../redux/alert.action.js";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { signInWithEmail, signUpWithEmail } from "../firebase";

const LoginPage = ({ setAlert }) => {
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleSignOut = () => {
        auth.signOut();
    };
    const handleSignIn = () => {
        signInWithEmail(email, password);
    };

    const handleSignUp = () => {
        signUpWithEmail(email, password);
    };


    return (
        <div>
            <div className="mb-10">
                <img
                    className="m-auto object-center w-80"
                    src="/images/friendlyRobo2.png"
                    alt="Logo"
                />
                <h1 className="text-4xl font-bold text-center">
                    {t("loginPage.title")}
                </h1>
            </div>

            {user ? (
                <div className="flex justify-center">
                    <SignOutButton handleSignOut={handleSignOut} />
                </div>
            ) : (
                <div className="flex flex-col items-center">
                    <p className="text-gray-700 mt-2">{t("loginPage.welcome")}</p>
                    <div className="mt-4">
                        <GoogleButton />
                    </div>
                    <div className="mt-10 flex flex-col items-center w-full ">
                        <p>{t("loginPage.orWithEmailAndPassword")}</p>
                        <div className="mt-4 flex items-center bg-white w-3/4 px-3 py-2 border border-gray-300 rounded-lg mb-2">
                            <AiOutlineMail className="text-gray-500" />
                            <input
                                type="email"
                                placeholder={t("loginPage.emailPlaceholder")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full ml-2 bg-transparent focus:outline-none"
                            />
                        </div>
                        <div className="flex items-center bg-white w-3/4 px-3 py-2 border border-gray-300 rounded-lg mb-4">
                            <AiOutlineLock className="text-gray-500" />
                            <input
                                type="password"
                                placeholder={t("loginPage.passwordPlaceholder")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full ml-2 bg-transparent focus:outline-none"
                            />
                        </div>
                        <button
                            onClick={handleSignIn}
                            className="w-3/4 bg-blue-500 text-white py-2 rounded-lg mb-2 focus:outline-none hover:bg-blue-600"
                        >
                            {t("loginPage.signInWithEmail")}
                        </button>
                        <button
                            onClick={handleSignUp}
                            className="w-3/4 bg-green-500 text-white py-2 rounded-lg focus:outline-none hover:bg-green-600"
                        >
                            {t("loginPage.signUpWithEmail")}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

LoginPage.propTypes = {
    setAlert: PropTypes.func.isRequired,

}


export default connect(null, { setAlert })(LoginPage)

