import React, { useState } from 'react';
import axios from 'axios';
import { deductCredits } from '../firebase';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

import Spinner from './microComponents/Spinner';
import LanguageSelector from './microComponents/LanguageSelector';
import DownloadTranscriptButton from './microComponents/DownloadTranscriptButton';


function Transcripts() {
    const [file, setFile] = useState(null);
    const [type, setType] = useState('')
    const [confirmed, setConfirmed] = useState(false)

    const [credits, setCredits] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [transcript, setTranscript] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const { t } = useTranslation();
    const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
    };


    const calculateCredits = async (file) => {
        // const fileSizeInKb = file.size / 1024;
        const isAudioFile = file.type.includes('audio');

        if (isAudioFile) {
            const audioBlob = new Blob([file], { type: file.type });
            const audioContext = new AudioContext();
            try {
                const audioBuffer = await audioContext.decodeAudioData(await audioBlob.arrayBuffer());
                const audioLengthInSeconds = audioBuffer.duration;
                const creditsPerMinute = 2;
                const creditsRequired = Math.ceil(audioLengthInSeconds / 60 * creditsPerMinute);
                return creditsRequired;
            } catch (error) {
                console.log(error);
                throw new Error('Error decoding audio data');
            }
        }

    };


    const handleDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];


        if (file.type === "audio/mpeg" || file.type === "audio/x-m4a" || file.type === "audio/wav") {
            setType("audio/mpeg")
        }
        else {
            setErrorMessage('Invalid file type. Please select a file with a valid Type');
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
            return;
        }

        if (!auth.currentUser) {
            setErrorMessage('Please Login to use this service');
            setTimeout(() => {
                setErrorMessage('');
            }, 10000);
            return;
        }
        setIsLoading(true);
        const newCredits = await calculateCredits(file);

        setCredits(newCredits);
        setFile(file);
        setIsLoading(false);

    };

    const splitAudioData = (data, chunkSize) => {
        const chunks = [];
        const byteLength = atob(data.split(',')[1]).length; // Convert base64 to bytes
        const numChunks = Math.ceil(byteLength / chunkSize);

        for (let i = 0; i < numChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, byteLength);
            const byteData = atob(data.split(',')[1]).slice(start, end);
            const chunk = data.split(',')[0] + ',' + btoa(byteData);
            chunks.push(chunk);
        }

        return chunks;
    };
    const handleConfirm = async () => {


        const isAudioFile = file.type.includes('audio');
        if (isAudioFile) {

            //read out file
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const audioData = reader.result;
                const chunkSize = 6 * 1024 * 1024; // 6MB in bytes
                const audioChunks = splitAudioData(audioData, chunkSize);
                // Process audio chunks
                const transcripts = [];


                for (const audioChunk of audioChunks) {
                    try {


                        setIsLoading(true);

                        const isProduction = process.env.NODE_ENV === 'production';
                        const apiUrl = isProduction
                            ? "https://us-central1-epic-summariser-ba8eb.cloudfunctions.net/transcribe"
                            : "http://127.0.0.1:5001/epic-summariser-ba8eb/us-central1/transcribe";

                        const response = await axios.post(
                            apiUrl,
                            {
                                audio: "free",
                                language: selectedLanguage,
                                test: audioChunk, // Send the base64-encoded audio data
                                mimetype: file.type // Send the mimetype of the file
                            },
                            {
                                headers: {
                                    // Add the following line to set the Content-Length header
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "POST",
                                    "Access-Control-Allow-Headers": "Content-Type",
                                },
                            }
                        );

                        transcripts.push(response.data.transcript);




                    } catch (error) {
                        console.log(error.response);
                    }
                }

                // Combine the transcripts and summaries
                const combinedTranscript = transcripts.join(' ');


                setTranscript(combinedTranscript);

                setConfirmed(true)
                deductCredits(auth.currentUser.uid, credits)
                setIsLoading(false);
            };

        }
    };
    return (
        <div className="flex relative flex-col items-center justify-center w-full">
            {isLoading && (
                <div className="absolute inset-0 flex justify-center items-center"
                    style={{
                        background: 'radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 70%)',
                    }}
                >
                    <Spinner />
                </div>
            )}
            <div className="relative flex">
                <div className=' mt-10 px-5'>
                    <p className='text-lg'>{t('fileDrop.acceptedFileTypes')}</p>
                    <p>{t('fileDrop.txt')}</p>
                    <p>{t('fileDrop.audio')}</p>
                </div>
                <div>
                    <Dropzone onDrop={handleDrop} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="mt-4">
                                <input {...getInputProps()} />
                                {errorMessage && (
                                    <div className="absolute bottom-4 bg-red-500 text-white px-4 py-2 rounded-md">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className="w-64 h-64 border-4 border-dashed border-gray-400 flex justify-center items-center">
                                    {file ? (
                                        <p className="text-gray-900 text-center text-sm font-medium uppercase">{t('fileDrop.selectedFile')} {file.name}</p>

                                    ) : (
                                        <p className="text-gray-900 text-lg font-medium uppercase">{t('fileDrop.dropInHere')}</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <LanguageSelector onLanguageChange={handleLanguageChange} />

                    {transcript && <DownloadTranscriptButton transcript={transcript} />}

                </div>
            </div>

            {
                file && (
                    <div>
                        <div className='flex justify-center'>
                            <button
                                className="bg-gray-800 mb-5 hover:bg-gray-900 text-white py-2 px-4 rounded-lg mt-4"
                                onClick={handleConfirm}
                            >
                                {t('fileDrop.submit')}
                            </button>
                        </div>


                        <p className=" text-md font-medium">{t('fileDrop.creditsToCompute')} {credits} </p>

                    </div>
                )
            }


        </div>
    );
}

export default Transcripts;