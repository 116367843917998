import React from "react"
import FileDrop from "./FileDrop"
import Explanation from "./Explanation"
import FlashcardGenerator from "./FlashcardGenerator"
import VotingFeature from "./VotingFeature"
import Benefits from "./Benefits"
import ExampleComponent from "./ExampleComponent"
import ImprovedParagraph from "./ImproveParagraph"

function Home() {
    return <div className="w-full ">
        <div className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
            <Explanation />

        </div>
        <div className="">
            <div className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
                <FileDrop />
                <FlashcardGenerator />
                {/* <VotingFeature /> */}
            </div>
        </div>
        <div className=" max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8 ">
            <Benefits />
            {/* <ExampleComponent /> */}
            {/* <ImprovedParagraph /> */}
        </div>
    </div>
}

export default Home