import React from "react";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="mt-20 w-full flex items-center justify-between flex-wrap bg-gray-800 py-3 px-6 shadow-md">
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p className="text-gray-500 text-sm text-center sm:text-left">
                    © 2023 Study Swift
                </p>
                <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    <Link
                        to="/impressum"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-4 hover:no-underline"
                    >
                        Impressum
                    </Link>
                    <Link
                        to="/contact"
                        className="block mt-4 lg:inline-block lg:mt-0 text-white font-semibold hover:text-gray-200 mr-4 hover:no-underline"
                    >
                        Contact
                    </Link>
                </span>
            </div>
        </footer>
    );
}

export default Footer;