import { useState } from "react";
import Background from "./microComponents/Background";
function Contact() {
    return (

        <div className="max-w-4xl mx-auto pt-20 flex">
            <Background />
            <div className="bg-white p-8 rounded-lg shadow-md flex-grow">
                <h2 className="text-2xl font-medium mb-8">Get in touch</h2>
                {/* <p className="mb-4">
                    <strong>Address:</strong> 123 Main St, Anytown, USA 12345
                </p> */}
                <p className="mb-4">
                    <strong>Email:</strong>{" "}
                    <a href="mailto:contact@example.com">info@study-swift.com</a>
                </p>
                <p className="mb-4">
                    <strong>Phone:</strong> +491759139160
                </p>


            </div>
            <div className="flex-none w-1/2 ">
                <img
                    src="./images/friendlyRobo.png"
                    alt="Contact"
                    className="w-full h-full object-cover"
                />
            </div>


        </div>
    );
}


// function Contact() {
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [message, setMessage] = useState("");
//     const [success, setSuccess] = useState(false);
//     const [error, setError] = useState(null);


//     const handleSubmit = (e) => {
//         e.preventDefault();
//         try {
//             //api to send email
//         } catch (error) {
//             setSuccess(false);
//             setError('Something went wrong. Please try again later.');
//         }
//     };

//     return (
//         <div className="max-w-4xl mx-auto pt-20 flex">
//             <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md flex-grow">
//                 <h2 className="text-2xl font-medium mb-8">Get in touch</h2>
//                 <div className="mb-6">
//                     <label htmlFor="name" className="sr-only">
//                         Name
//                     </label>
//                     <input
//                         id="name"
//                         name="name"
//                         type="text"
//                         autoComplete="name"
//                         required
//                         className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                         placeholder="Name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-6">
//                     <label htmlFor="email" className="sr-only">
//                         Email address
//                     </label>
//                     <input
//                         id="email"
//                         name="email"
//                         type="email"
//                         autoComplete="email"
//                         required
//                         className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                         placeholder="Email address"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                     />
//                 </div>
//                 <div className="mb-6">
//                     <label htmlFor="message" className="sr-only">
//                         Message
//                     </label>
//                     <textarea
//                         id="message"
//                         name="message"
//                         rows="5"
//                         required
//                         className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                         placeholder="Message"
//                         value={message}
//                         onChange={(e) => setMessage(e.target.value)}
//                     ></textarea>
//                 </div>
//                 <button
//                     type="submit"
//                     className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                     Send
//                 </button>
//             </form>
//             <div className="flex-none w-1/2 ">
//                 <img
//                     src="./images/friendlyRobo.png"
//                     alt="Contact"
//                     className="w-full h-full object-cover"
//                 />
//             </div>
//         </div>

//     );
// }

export default Contact;
