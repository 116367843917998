import { useState } from 'react';
import axios from 'axios';
import Spinner from './microComponents/Spinner';
import { useTranslation } from 'react-i18next';

const FlashcardGenerator = () => {
    const [topic, setTopic] = useState('');
    const [flashcards, setFlashcards] = useState(null);
    const [confirmed, setConfirmed] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            setIsLoading(true)
            const isProduction = process.env.NODE_ENV === 'production';
            const apiUrl = isProduction
                ? "https://us-central1-epic-summariser-ba8eb.cloudfunctions.net/generateFlashcardIdea"
                : "http://127.0.0.1:5001/epic-summariser-ba8eb/us-central1/generateFlashcardIdea";

            const response = await axios.post(apiUrl,
                {
                    topic: topic,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'POST',
                        'Access-Control-Allow-Headers': 'Content-Type',
                    },
                }
            );


            const rawDataString = response.data.idea
            // .replace(/\n/g, '')
            // .replace(/(\w+):/g, (_, key) => `"${key}":`);

            const JsonArray = await JSON.parse(rawDataString)

            // const newflashcards = convertFlashcards(JSON.stringify(response.data.idea))
            setFlashcards(JsonArray);
            setIsLoading(false)
            setConfirmed(true)


        } catch (error) {

            console.log(error.response);
        }

    };

    return (
        <div className='mt-8 md:mt-20 relative'>
            {isLoading && (

                <div className="fixed left-0 inset-0 w-screen flex flex-col justify-center z-10 opacity-90 bg-gray-800 items-center"

                >
                    <Spinner />
                </div>
            )}
            <h1 className="text-2xl md:text-4xl font-bold text-center mb-4">{t('flashcardGenerator.title')}</h1>
            <p className="text-center mb-4">{t('flashcardGenerator.enterTopic')}</p>
            <form className="flex flex-col md:flex-row justify-center items-start md:items-center" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={topic}
                    onChange={(event) => setTopic(event.target.value)}
                    placeholder={t('flashcardGenerator.placeholder')}
                    className="border border-gray-300 px-4 py-2 rounded-lg mb-4 md:mb-0 md:mr-2 w-full md:w-auto"
                />
                <button
                    type="submit"
                    className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded-lg w-full md:w-auto"
                >
                    {t('flashcardGenerator.generate')}
                </button>
            </form>

            {confirmed ? (
                <div className="mt-8">
                    <h2 className="text-xl md:text-2xl font-bold mb-2">{topic}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {flashcards.slice(0, 3).map((flashcard, index) => (
                            <div key={index} className="border border-gray-300 p-4 rounded-lg">
                                <p className="text-lg font-bold mb-4">{flashcard.Front}</p>
                                <hr className="my-4" />
                                <p className="text-lg">{flashcard.Back}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default FlashcardGenerator;