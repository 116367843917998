import React from 'react';
import { FaClock, FaLightbulb, FaLaptop } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const Benefits = () => {
    const { t } = useTranslation();
    const benefitsList = [
        {
            icon: FaClock,
            title: t('benefits.timeSaving.title'),
            description: t('benefits.timeSaving.description'),
        },
        {
            icon: FaLightbulb,
            title: t('benefits.enhancedComprehension.title'),
            description: t('benefits.enhancedComprehension.description'),
        },
        {
            icon: FaLaptop,
            title: t('benefits.flexibleLearning.title'),
            description: t('benefits.flexibleLearning.description'),
        },
    ];

    return (
        <div className="benefits max-w-4xl mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-8 text-center">{t('benefits.heading')}</h2>
            <ul className="benefits-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {benefitsList.map((benefit, index) => (
                    <li key={index} className="benefit-item flex flex-col items-center text-center">
                        <div className="icon mb-4">
                            <benefit.icon size="2em" />
                        </div>
                        <div className="content">
                            <h3 className="text-lg font-semibold mb-2">{benefit.title}</h3>
                            <p>{benefit.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default Benefits;
