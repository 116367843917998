import React from 'react';
import { FaLightbulb, FaClock, FaHandshake } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function ValuesSection() {
    const { t } = useTranslation();

    return (
        <section className="text-black py-20">
            <div className="container mx-auto flex flex-wrap justify-center">
                <div className="w-full text-center mb-16">
                    <h2 className="text-4xl font-bold leading-tight mb-4">
                        {t('values.ourValues')}
                    </h2>
                </div>
                <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
                    <div className="text-center">
                        <div className="inline-block p-6 rounded-full bg-gray-800">
                            <FaLightbulb className="text-white text-3xl" />
                        </div>
                        <h3 className="text-2xl font-bold mt-8 mb-4">
                            {t('values.creativity')}
                        </h3>
                        <p className="text-gray-400">
                            {t('values.creativityDescription')}
                        </p>
                    </div>
                </div>
                <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
                    <div className="text-center">
                        <div className="inline-block p-6 rounded-full bg-gray-800">
                            <FaClock className="text-white text-3xl" />
                        </div>
                        <h3 className="text-2xl font-bold mt-8 mb-4">
                            {t('values.timeSaving')}
                        </h3>
                        <p className="text-gray-400">
                            {t('values.timeSavingDescription')}
                        </p>
                    </div>
                </div>
                <div className="w-full md:w-1/3 px-4">
                    <div className="text-center">
                        <div className="inline-block p-6 rounded-full bg-gray-800">
                            <FaHandshake className="text-white text-3xl" />
                        </div>
                        <h3 className="text-2xl font-bold mt-8 mb-4">
                            {t('values.support')}
                        </h3>
                        <p className="text-gray-400">
                            {t('values.supportDescription')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ValuesSection;
